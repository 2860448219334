import { css, html, LitElement, nothing } from "lit"
import { capFirst, isBreakpoint } from "#js/components/utils"
import {
  getAlgoliaAnalyticsData,
  sendAlgoliaClickEvent,
} from "#js/integrations/algoliaSearch"
import { msg, str, updateWhenLocaleChanges } from "@lit/localize"
import { TrackingMixin } from "#js/components/TrackingMixin"
import { atBreakpoint } from "#js/components/styles"
import { getUrlWithTracking } from "#js/components/utm"
import { picture } from "#js/components/ui"
import { setLocale } from "#js/components/lit-i18n"
import { unsafeHTML } from "lit/directives/unsafe-html.js"

class CalendarEvent extends LitElement {
  static properties = {
    event: { type: Object },
    trackingInformation: { type: Object },
  }

  static styles = css`
    .event {
      display: grid;
      grid-template-columns: 4.5em 1fr;
      gap: var(--space);

      ${
    atBreakpoint(
      "mobile",
      css`
        gap: var(--space--half);
        grid-template-columns: 1fr;
        padding-left: var(--space);
        border-left: thin solid var(--darker-gray);
        padding-bottom: var(--space);

        &::before {
          content: "";
          width: 0.75em;
          height: 0.75em;
          background-color: var(--darker-gray);
          border-radius: 10rem;
          position: absolute;
          left: calc(-0.375em + 0.5px);
          margin-top: calc(var(--space--half) - 0.3em);
        }
      `,
    )
  }

      .time {
        display: flex;
        flex-flow: column;
        align-self: center;

        ${
    atBreakpoint(
      "mobile",
      css`
          flex-flow: row nowrap;
          gap: 0.5em;
        `,
    )
  }
      }

      .event__card {
        display: grid;
        grid-template-columns: 36% 1fr;

        ${
    atBreakpoint(
      "mobile",
      css`
          grid-template-columns: 1fr;
        `,
    )
  }
      }
  `

  constructor() {
    super()
    if (!this.trackingInformation) {
      this.trackingInformation = {}
    }
  }

  render() {
    if (!this.event) return nothing
    return html`
      <link rel="stylesheet" href="${globalThis.styleFilePath}">
      <div class="event">
        <div class="time">
          ${this.getTime(this.event.start_date)}
          ${msg("to", { desc: "Distance between two times" })}
          ${this.getTime(this.event.end_date)}
        </div>
        <a href="${getUrlWithTracking(this.event.public_url, this.trackingInformation)}"
           @click="${(event) => this.sendSelectItem(event, this.event)}"
           class="event__card card card--interactive">
          ${this.getCardLayer()}
          <div class="card__content">
            <h3 style="line-height: 1.3">
              ${this.event.parent_object_title}${this.getSubtitle()}
            </h3>
            <p>
              ${this.event.parent_object_subtitle}
            </p>
          </div>
        </a>
      </div>
    `
  }

  /**
   * Format a date as time.
   * @param {Date} date - date to format as time
   * @returns {*} formatted time as HTML
   */
  getTime(date) {
    return html`
      <strong style="white-space: nowrap;">
        ${
      date.toLocaleString(globalThis.language, {
        hour: "numeric",
        minute: "numeric",
      })
    }
        ${globalThis.language === "de" ? "Uhr" : nothing}
      </strong>
    `
  }

  getSubtitle() {
    if (this.event.title) {
      return html`<small style="color: var(--black-67);">&nbsp;&mdash;&nbsp;${this.event.title}</small>
      `
    }
  }

  getCardLayer() {
    if (isBreakpoint("desktop")) {
      return html`
        <div class="card__layer">
          ${this.getPicture()}
          <div class="ribbon__wrapper">
            ${this.getRibbon()}
          </div>
        </div>
      `
    }
  }

  getPicture() {
    if (this.event.picture) {
      return html`
          ${
        unsafeHTML(
          picture(this.event.picture, this.event.parent_object_title, "3/2", {}, {
            loading: "lazy",
          }),
        )
      }
      `
    }
  }

  getRibbon() {
    if (this.event.previouslyBooked) {
      return html`
        <div class="ribbon ribbon--gray">
          ${msg(str`previously booked`)}
        </div>
      `
    } else if (this.event.activity_type_display) {
      return html`
        <div class="ribbon">
          ${this.event.activity_type_display}
        </div>
      `
    }
  }

  sendSelectItem(event, item) {
    sendAlgoliaClickEvent(item.algoliaAnalytics)
  }
}

customElements.define("calendar-event", CalendarEvent)

class EventCalendar extends TrackingMixin {
  static properties = {
    groupedEvents: { type: Array },
    error: { type: Boolean, attribute: false },
    previouslyBookedOffers: { type: Array, attribute: false },
  }

  constructor() {
    super()
    setLocale(globalThis.language)
    updateWhenLocaleChanges(this)
    this.groupedEvents = []
  }

  static styles = css`
    .calendar {
      width: 100%;
      max-width: 840px;
      margin: 0 auto;
      display: flex;
      flex-flow: column;
      gap: var(--space);
      position: relative;

      ${
    atBreakpoint(
      "mobile",
      css`
        gap: 0;
      `,
    )
  }
    }
  `

  applySettings(settings) {
    this.previouslyBookedOffers = settings.previously_booked_offers
  }

  render() {
    return html`
      <div class="calendar">
        ${
      Object.entries(this.groupedEvents).map(([date, events]) =>
        html`
          <h2>${date}</h2>
          ${
          events.map((event) =>
            html`
            <calendar-event
              .event=${event}
              .trackingInformation=${this.trackingInformation}>
            </calendar-event>
          `
          )
        }
        `
      )
    }
      </div>
    `
  }

  eventsChanged(events, hitsPerPage, currentPage, algoliaQueryId) {
    const newEvents = this.groupEventsByDate(
      events.map((hit, index) => {
        let previouslyBooked = false
        if (hit.objectID.split("-")[0] === "bookingoption") {
          previouslyBooked = this.previouslyBookedOffers.includes(
            parseInt(hit.parent_object_id.split("-")[1]),
          )
        }
        return {
          ...hit,
          start_date: new Date(hit.start_date * 1000),
          end_date: new Date(hit.end_date * 1000),
          previouslyBooked,
          algoliaAnalytics: getAlgoliaAnalyticsData(
            hit,
            index,
            hitsPerPage,
            currentPage,
            algoliaQueryId,
          ),
        }
      }),
    )
    if (currentPage === 0) {
      // Replace all events if new search query is made, hence currentPage is 0.
      this.groupedEvents = newEvents
    } else {
      // Extend existing events if more search results are loaded.
      this.groupedEvents = {
        ...this.extendGroupedEvents(newEvents, this.groupedEvents),
      }
    }
  }

  /**
   * Group events by date.
   * @param {Array} events - events to group
   * @returns {*} grouped events
   */
  groupEventsByDate(events) {
    return events.reduce((acc, event) => {
      let date
      if (event.start_date.toDateString() === new Date().toDateString()) {
        date = capFirst(msg("today"))
      } else if (
        event.start_date.toDateString() ===
          new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toDateString()
      ) {
        date = capFirst(msg("tomorrow"))
      } else {
        date = event.start_date.toLocaleDateString(globalThis.language, {
          weekday: "short",
          month: "short",
          day: "numeric",
        })
      }
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(event)
      return acc
    }, {})
  }

  /**
   * Extend the existing events with new events.
   * @param {*} newEvents events to extend the existing events with
   * @param {*} oldEvents existing events
   * @returns {*} grouped events
   */
  extendGroupedEvents(newEvents, oldEvents) {
    for (const [date, events] of Object.entries(newEvents)) {
      if (!oldEvents[date]) {
        oldEvents[date] = []
      }
      oldEvents[date].push(...events)
    }
    return oldEvents
  }
}

customElements.define("event-calendar", EventCalendar)
